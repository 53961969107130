import React from 'react';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  componentDidMount () {
    //let _this = this;

  }

  componentWillUnmount() {

  }

  
  render() {
    return (        
      <div className="footer">
        <div className="footer-title">~signal</div>
      </div>
    );
  }
}

export default Footer;
